
.active {
  color: #ff9d00;
}
.header-container {
  height: 100%;
}
.popover {
  .ant-popover-content {
    margin-top: 12px;
    position: relative;
    right: -14px;
  }
  .ant-popover-inner {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.1) !important;
    font-size: 15px;
    padding: 0 16px 0 10px;
    width: 140px;
    box-shadow: none;
    border-radius: 0;
    .ant-popover-inner-content {
      color: white !important;
      line-height: 22px;
      text-align: left;
      text-transform: uppercase;
    }
  }
  .menu-item {
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }
}
@primary-color: #1DA57A;