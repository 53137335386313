.footer-wrap {
  width: 100%;
  // height: 315px;
  position: relative;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 157, 0, 0.25) 0%,
    1%,
    rgba(250, 154, 0, 0) 100%
  );
  border-top: 1px solid rgba(255, 157, 0, 0.69);

  .desp {
    color: rgba(255, 255, 255, 0.55);
  }

  .coyright {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }
}

@primary-color: #1DA57A;