*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container{
  width: 100%;
}

@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
}

@media (min-width: 1536px){
  .container{
    max-width: 1536px;
  }
}

.fixed{
  position: fixed;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.bottom-0{
  bottom: 0px;
}

.left-0{
  left: 0px;
}

.right-0{
  right: 0px;
}

.top-0{
  top: 0px;
}

.top-1\/3{
  top: 33.333333%;
}

.top-\[-80px\]{
  top: -80px;
}

.z-10{
  z-index: 10;
}

.mx-auto{
  margin-left: auto;
  margin-right: auto;
}

.mr-50{
  margin-right: 50px;
}

.mt-10{
  margin-top: 10px;
}

.box-border{
  box-sizing: border-box;
}

.block{
  display: block;
}

.flex{
  display: flex;
}

.grid{
  display: grid;
}

.hidden{
  display: none;
}

.h-110{
  height: 110px;
}

.h-24{
  height: 24px;
}

.h-252{
  height: 252px;
}

.h-552{
  height: 552px;
}

.h-full{
  height: 100%;
}

.h-screen{
  height: 100vh;
}

.w-24{
  width: 24px;
}

.w-572{
  width: 572px;
}

.w-\[1\.2vw\]{
  width: 1.2vw;
}

.max-w-full{
  max-width: 100%;
}

.cursor-pointer{
  cursor: pointer;
}

.select-none{
  -webkit-user-select: none;
          user-select: none;
}

.flex-col{
  flex-direction: column;
}

.items-center{
  align-items: center;
}

.justify-center{
  justify-content: center;
}

.justify-between{
  justify-content: space-between;
}

.gap-10{
  gap: 10px;
}

.gap-100{
  gap: 100px;
}

.overflow-hidden{
  overflow: hidden;
}

.whitespace-nowrap{
  white-space: nowrap;
}

.rounded-\[10px\]{
  border-radius: 10px;
}

.rounded-full{
  border-radius: 9999px;
}

.border{
  border-width: 1px;
}

.border-solid{
  border-style: solid;
}

.border-transparent{
  border-color: transparent;
}

.bg-\[\#FF9D00\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 157 0 / var(--tw-bg-opacity));
}

.bg-white\/10{
  background-color: rgb(255 255 255 / 0.1);
}

.bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.px-20{
  padding-left: 20px;
  padding-right: 20px;
}

.pt-31{
  padding-top: 31px;
}

.text-center{
  text-align: center;
}

.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-white\/60{
  color: rgb(255 255 255 / 0.6);
}

.backdrop-blur-\[10px\]{
  --tw-backdrop-blur: blur(10px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm{
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

body,
  html{
  width: 100%;
  height: 100%;
  color: #FFF !important;
  background: #202020;
  font-family: 'rb-b';
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  overflow-x: hidden;
}

.home-container {
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
}

.sans {
  font-family: HarmonyOS_Sans_Black;
}

.hover\:border-\[\#FF9D00\]:hover{
  --tw-border-opacity: 1;
  border-color: rgb(255 157 0 / var(--tw-border-opacity));
}

.hover\:bg-transparent:hover{
  background-color: transparent;
}

.hover\:text-\[\#FF9D00view2-item-text\]:hover{
  color: #FF9D00view2-item-text;
}

@media (min-width: 768px){
  .md\:right-\[-70px\]{
    right: -70px;
  }

  .md\:top-1\/3{
    top: 33.333333%;
  }

  .md\:mx-100{
    margin-left: 100px;
    margin-right: 100px;
  }

  .md\:mx-auto{
    margin-left: auto;
    margin-right: auto;
  }

  .md\:ml-18{
    margin-left: 18px;
  }

  .md\:mr-50{
    margin-right: 50px;
  }

  .md\:mt-113{
    margin-top: 113px;
  }

  .md\:mt-116{
    margin-top: 116px;
  }

  .md\:mt-150{
    margin-top: 150px;
  }

  .md\:mt-20{
    margin-top: 20px;
  }

  .md\:mt-24{
    margin-top: 24px;
  }

  .md\:mt-25{
    margin-top: 25px;
  }

  .md\:mt-44{
    margin-top: 44px;
  }

  .md\:mt-60{
    margin-top: 60px;
  }

  .md\:mt-70{
    margin-top: 70px;
  }

  .md\:mt-92{
    margin-top: 92px;
  }

  .md\:hidden{
    display: none;
  }

  .md\:h-1108{
    height: 1108px;
  }

  .md\:h-140{
    height: 140px;
  }

  .md\:h-155{
    height: 155px;
  }

  .md\:h-215{
    height: 215px;
  }

  .md\:h-250{
    height: 250px;
  }

  .md\:h-315{
    height: 315px;
  }

  .md\:h-335{
    height: 335px;
  }

  .md\:h-36{
    height: 36px;
  }

  .md\:h-46{
    height: 46px;
  }

  .md\:h-60{
    height: 60px;
  }

  .md\:h-67{
    height: 67px;
  }

  .md\:h-75{
    height: 75px;
  }

  .md\:h-750{
    height: 750px;
  }

  .md\:h-80{
    height: 80px;
  }

  .md\:h-92{
    height: 92px;
  }

  .md\:h-99{
    height: 99px;
  }

  .md\:w-1200{
    width: 1200px;
  }

  .md\:w-155{
    width: 155px;
  }

  .md\:w-210{
    width: 210px;
  }

  .md\:w-219{
    width: 219px;
  }

  .md\:w-240{
    width: 240px;
  }

  .md\:w-321{
    width: 321px;
  }

  .md\:w-36{
    width: 36px;
  }

  .md\:w-613{
    width: 613px;
  }

  .md\:w-631{
    width: 631px;
  }

  .md\:w-80{
    width: 80px;
  }

  .md\:w-92{
    width: 92px;
  }

  .md\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:items-center{
    align-items: center;
  }

  .md\:justify-center{
    justify-content: center;
  }

  .md\:gap-10{
    gap: 10px;
  }

  .md\:gap-134{
    gap: 134px;
  }

  .md\:gap-26{
    gap: 26px;
  }

  .md\:gap-30{
    gap: 30px;
  }

  .md\:gap-40{
    gap: 40px;
  }

  .md\:gap-45{
    gap: 45px;
  }

  .md\:gap-50{
    gap: 50px;
  }

  .md\:gap-60{
    gap: 60px;
  }

  .md\:rounded-\[6px\]{
    border-radius: 6px;
  }

  .md\:p-40{
    padding: 40px;
  }

  .md\:px-30{
    padding-left: 30px;
    padding-right: 30px;
  }

  .md\:py-40{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .md\:py-60{
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .md\:py-45{
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .md\:pb-130{
    padding-bottom: 130px;
  }

  .md\:pb-143{
    padding-bottom: 143px;
  }

  .md\:pb-177{
    padding-bottom: 177px;
  }

  .md\:pb-70{
    padding-bottom: 70px;
  }

  .md\:pr-33{
    padding-right: 33px;
  }

  .md\:pt-150{
    padding-top: 150px;
  }

  .md\:pt-187{
    padding-top: 187px;
  }

  .md\:pt-232{
    padding-top: 232px;
  }

  .md\:pt-55{
    padding-top: 55px;
  }

  .md\:text-18{
    font-size: 18px;
  }

  .md\:text-22{
    font-size: 22px;
  }

  .md\:text-24{
    font-size: 24px;
  }

  .md\:text-30{
    font-size: 30px;
  }

  .md\:text-34{
    font-size: 34px;
  }

  .md\:text-66{
    font-size: 66px;
  }

  .md\:text-80{
    font-size: 80px;
  }

  .md\:text-16{
    font-size: 16px;
  }

  .md\:text-12{
    font-size: 12px;
  }

  .md\:text-14{
    font-size: 14px;
  }

  .md\:leading-\[60px\]{
    line-height: 60px;
  }

  .md\:leading-\[92px\]{
    line-height: 92px;
  }

  .md\:leading-\[99px\]{
    line-height: 99px;
  }

  .md\:odd\:rounded-l-\[155px\]:nth-child(odd){
    border-top-left-radius: 155px;
    border-bottom-left-radius: 155px;
  }

  .md\:odd\:from-\[\#ff9d0021\]:nth-child(odd){
    --tw-gradient-from: #ff9d0021 var(--tw-gradient-from-position);
    --tw-gradient-from-position:  ;
    --tw-gradient-to: rgb(255 157 0 / 0)  var(--tw-gradient-from-position);
    --tw-gradient-to-position:  ;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:odd\:to-\[\#ff9d0000\]:nth-child(odd){
    --tw-gradient-to: #ff9d0000 var(--tw-gradient-to-position);
    --tw-gradient-to-position:  ;
  }

  .md\:even\:flex-row-reverse:nth-child(even){
    flex-direction: row-reverse;
  }

  .md\:even\:rounded-r-\[155px\]:nth-child(even){
    border-top-right-radius: 155px;
    border-bottom-right-radius: 155px;
  }

  .md\:even\:from-\[\#ff9d0000\]:nth-child(even){
    --tw-gradient-from: #ff9d0000 var(--tw-gradient-from-position);
    --tw-gradient-from-position:  ;
    --tw-gradient-to: rgb(255 157 0 / 0)  var(--tw-gradient-from-position);
    --tw-gradient-to-position:  ;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:even\:to-\[\#ff9d0021\]:nth-child(even){
    --tw-gradient-to: #ff9d0021 var(--tw-gradient-to-position);
    --tw-gradient-to-position:  ;
  }
}

@media (max-width: 767px){
  .mmd\:right-0{
    right: 0px;
  }

  .mmd\:top-160{
    top: 160px;
  }

  .mmd\:top-200{
    top: 200px;
  }

  .mmd\:mx-auto{
    margin-left: auto;
    margin-right: auto;
  }

  .mmd\:mb-20{
    margin-bottom: 20px;
  }

  .mmd\:ml-7{
    margin-left: 7px;
  }

  .mmd\:mt-10{
    margin-top: 10px;
  }

  .mmd\:mt-20{
    margin-top: 20px;
  }

  .mmd\:mt-25{
    margin-top: 25px;
  }

  .mmd\:mt-30{
    margin-top: 30px;
  }

  .mmd\:mt-37{
    margin-top: 37px;
  }

  .mmd\:mt-50{
    margin-top: 50px;
  }

  .mmd\:mt-7{
    margin-top: 7px;
  }

  .mmd\:mt-75{
    margin-top: 75px;
  }

  .mmd\:mt-9{
    margin-top: 9px;
  }

  .mmd\:flex{
    display: flex;
  }

  .mmd\:hidden{
    display: none;
  }

  .mmd\:h-14{
    height: 14px;
  }

  .mmd\:h-140{
    height: 140px;
  }

  .mmd\:h-20{
    height: 20px;
  }

  .mmd\:h-21{
    height: 21px;
  }

  .mmd\:h-24{
    height: 24px;
  }

  .mmd\:h-29{
    height: 29px;
  }

  .mmd\:h-295{
    height: 295px;
  }

  .mmd\:h-334{
    height: 334px;
  }

  .mmd\:h-40{
    height: 40px;
  }

  .mmd\:h-44{
    height: 44px;
  }

  .mmd\:h-46{
    height: 46px;
  }

  .mmd\:h-56{
    height: 56px;
  }

  .mmd\:h-\[36px\]{
    height: 36px;
  }

  .mmd\:w-104{
    width: 104px;
  }

  .mmd\:w-130{
    width: 130px;
  }

  .mmd\:w-14{
    width: 14px;
  }

  .mmd\:w-200{
    width: 200px;
  }

  .mmd\:w-29{
    width: 29px;
  }

  .mmd\:w-40{
    width: 40px;
  }

  .mmd\:w-46{
    width: 46px;
  }

  .mmd\:w-67{
    width: 67px;
  }

  .mmd\:w-90{
    width: 90px;
  }

  .mmd\:w-\[80\%\]{
    width: 80%;
  }

  .mmd\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .mmd\:flex-col{
    flex-direction: column;
  }

  .mmd\:items-center{
    align-items: center;
  }

  .mmd\:gap-10{
    gap: 10px;
  }

  .mmd\:gap-13{
    gap: 13px;
  }

  .mmd\:gap-15{
    gap: 15px;
  }

  .mmd\:gap-20{
    gap: 20px;
  }

  .mmd\:gap-22{
    gap: 22px;
  }

  .mmd\:gap-3{
    gap: 3px;
  }

  .mmd\:gap-6{
    gap: 6px;
  }

  .mmd\:rounded-\[10px\]{
    border-radius: 10px;
  }

  .mmd\:bg-white\/5{
    background-color: rgb(255 255 255 / 0.05);
  }

  .mmd\:p-15{
    padding: 15px;
  }

  .mmd\:p-20{
    padding: 20px;
  }

  .mmd\:px-14{
    padding-left: 14px;
    padding-right: 14px;
  }

  .mmd\:px-15{
    padding-left: 15px;
    padding-right: 15px;
  }

  .mmd\:px-20{
    padding-left: 20px;
    padding-right: 20px;
  }

  .mmd\:py-15{
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mmd\:py-25{
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .mmd\:py-40{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mmd\:pb-17{
    padding-bottom: 17px;
  }

  .mmd\:pb-51{
    padding-bottom: 51px;
  }

  .mmd\:pb-58{
    padding-bottom: 58px;
  }

  .mmd\:pt-25{
    padding-top: 25px;
  }

  .mmd\:pt-50{
    padding-top: 50px;
  }

  .mmd\:pt-94{
    padding-top: 94px;
  }

  .mmd\:pt-96{
    padding-top: 96px;
  }

  .mmd\:text-left{
    text-align: left;
  }

  .mmd\:text-10{
    font-size: 10px;
  }

  .mmd\:text-12{
    font-size: 12px;
  }

  .mmd\:text-13{
    font-size: 13px;
  }

  .mmd\:text-14{
    font-size: 14px;
  }

  .mmd\:text-16{
    font-size: 16px;
  }

  .mmd\:text-20{
    font-size: 20px;
  }

  .mmd\:text-7{
    font-size: 7px;
  }

  .mmd\:leading-\[29px\]{
    line-height: 29px;
  }

  .mmd\:leading-\[36px\]{
    line-height: 36px;
  }

  .mmd\:leading-\[44px\]{
    line-height: 44px;
  }

  .mmd\:text-\[\#FF9D00\]{
    --tw-text-opacity: 1;
    color: rgb(255 157 0 / var(--tw-text-opacity));
  }

  .mmd\:backdrop-blur-\[10px\]{
    --tw-backdrop-blur: blur(10px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .mmd\:backdrop-blur-md{
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }
}
